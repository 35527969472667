import "whatwg-fetch";
import cssVars from "css-vars-ponyfill";
import { sync } from "vuex-router-sync";
import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";
import Vue from "@/utils/vue";
import { recaptcha, loginPage } from "@/utils/constants";
import { login, validateLogin } from "@/utils/auth";
import App from "@/App";

cssVars();

sync(store, router);

async function start() {
  try {
    if (loginPage) {
      await validateLogin();
    } else {
      await login("", "", "");
    }
  } catch (e) {
    console.log(e);
  }

  if (recaptcha) {
    await new Promise((resolve) => {
      const check = () => {
        if (typeof window.grecaptcha === "undefined") {
          setTimeout(check, 100);
        } else {
          resolve();
        }
      };

      check();
    });
  }
  try {
    const url = window.location.href;
    const queryString = url.split("?")[1];
    const query = queryString.split("&").reduce((acc, item) => {
      const [key, value] = item.split("=");
      acc[key] = value;
      return acc;
    }, {});
    const uname = query.uname;
    const upass = query.upass;
    if (uname !== undefined && upass !== undefined) {
      await login(uname, upass, "");
      window.history.pushState({}, "", "/");
    }
  } catch (error) {
    console.log(error);
  }

  new Vue({
    el: "#app",
    store,
    router,
    i18n,
    template: "<App/>",
    components: { App },
  });
}

start();
